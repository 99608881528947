/**
 * hook for API communication
 */
import React, {useCallback, useContext} from 'react'
import axios from "axios";

const ApiContext=React.createContext();
ApiContext.displayName='ApiContext';

export function ApiContextProvider({children}) {

    let logging=false;
    //let logging=true;

    const getUrlParam = useCallback((name) => {
        const queryParams = new URLSearchParams(window.location.search);
        console.log('urlparam - '+name,queryParams.get(name));
        return queryParams.get(name);
    },[]);

    const authBaseToken = useCallback(()=> {
        return getUrlParam('token')??'';
    },[getUrlParam]);;
    const apiBaseUrl = useCallback(()=> {
        return 'http://localhost:'+getUrlParam('p')+'/api/';
    },[getUrlParam]);

    /**
     * Bind url templates with values by :name
     *
     * @param _template_str the URL template to fill
     * @param _values_obj the object with named values to bind
     * @param _separator the name-value separator character
     * @returns {null|*} null on error, urlstring on success
     */
    const bindParam = (_template_str,_values_obj,_separator=':') => {
        if(!_template_str
            ||
            _template_str.length===0
        ) {
            return null;
        }

        if(!_values_obj
            || Object.keys(_values_obj).length<1
        ) {
            return _template_str;
        }

        let ret=_template_str;

        Object.keys(_values_obj).forEach( key=> {
            const mask=_separator+key;
            ret=ret.replace(mask,_values_obj[key]);
        });

        return ret;
    }

    /**
     * AXIOS wrapper for debuggong and logging
     *
     * @param _caller the function name who call this function, for logging
     * @param _method the call method name string
     * @param _uri the API endpoint uri (not the full address)
     * @param data the data object to pass with the call     *
     */
    const doRawApiCall = useCallback((_caller,_method, _uri, data=undefined) => {

        const uri=`${apiBaseUrl()}${_uri}`;
        const authToken=authBaseToken();

        const _headers = authToken !== false ? {'DToken': `Bearer ${authToken}`} : {};

        if(logging) {
            console.log(`${_caller}.doApiCall`,authToken);
            console.log('uri',uri);
            console.log('method',_method);
            console.log('headers',_headers);
            console.log('data',data);
        }
        return axios({
            method: _method,
            url: uri,
            data,
            headers: _headers
        });
    },[authBaseToken,apiBaseUrl,logging]);

    /**
     * API call wrapper for easy use
     *
     * @param _caller the function name who call this function, for logging
     * @param _method the call method name string
     * @param _uri the API endpoint uri (not the full address)
     * @param onSuccess the function to call on success, response data and original input data passed
     * @param onFailure the function to call on success, error data passed
     * @param data the data object to pass with the call
     */
    const doApiCall = useCallback((_caller,_method, _uri, onSuccess, onFailure=false,data=undefined)=>{
        doRawApiCall(
            `useCallback(${_caller})`,
            _method,
            _uri,
            data
        ).then(res => {
            if(logging) {
                console.log(`${_caller}.doApiCall.then`);
                console.table(res.data);
            }
            onSuccess(res.data,data);
        }).catch(err => {
            if(logging) {
                console.log(`${_caller}.doApiCall.catch`);
                console.table(err?.response?.data);
            }
            if(onFailure!==false) {
                onFailure(err?.response?.data?.error,err);
            }
        });
    },[doRawApiCall,logging]);

    const ApiGetLogo = useCallback((onSuccess,onFailure=false)=>{
        doApiCall(
            'ApiGetLogo',
            'GET',
            'logo',
            onSuccess,
            onFailure
        );
    },[doApiCall]);

    const ApiGetJobs = useCallback((onSuccess,onFailure=false)=>{
        doApiCall(
            'ApiGetJobs',
            'GET',
            'jobs',
            onSuccess,
            onFailure
        );
    },[doApiCall]);

    const ApiGetInfo = useCallback((onSuccess,onFailure=false)=>{
        doApiCall(
            'ApiGetInfo',
            'GET',
            'info',
            onSuccess,
            onFailure
        );
    },[doApiCall]);

    const ApiGetWeather = useCallback((onSuccess,onFailure=false)=>{
        doApiCall(
            'ApiGetWeather',
            'GET',
            'weather',
            onSuccess,
            onFailure
        );
    },[doApiCall]);

    return (
        <ApiContext.Provider value={{
            ApiGetLogo,ApiGetJobs,ApiGetInfo,ApiGetWeather
        }}>
            {children}
        </ApiContext.Provider>
    );
}

export function useApi() {
    return useContext(ApiContext);
}
