import {Grid} from "@mui/material";
import {ClockWidget} from "./ClockWidget";
import {DateWidget} from "./DateWidget";
import {LogoWidget} from "./LogoWidget";
import {WeatherWidget} from "./WeatherWidget";
import {useEffect, useRef, useState} from "react";
import {useApi} from "../hooks/UseApi";

export const InnerLogoPanel = () => {
    const [data,setData]=useState(null);

    const weatherLoading = useRef(false);
    const {ApiGetWeather} = useApi();

    useEffect(()=> {
        if(!weatherLoading.current && data===null) {
            weatherLoading.current=true;
            ApiGetWeather(
                (d) => {
                    if (d?.data && d?.success) {
                        console.log('InnerLogoPanel fetch0', d.data);
                        setData(d.data);
                        weatherLoading.current=false
                    } else {
                        console.error('InnerLogoPanel fetch1', d);
                        setData(null);
                        weatherLoading.current=false
                    }
                }
                , (error) => {
                    console.error('InnerLogoPanel fetch2', error);
                    setData(null);
                    weatherLoading.current=false
                });
        }
    },[ApiGetWeather,setData]);

    useEffect(()=> {
        if(data!==null) {
            console.log('InnerLogoPanel data', data)
        }
    },[data]);

    return data!==null && (
        <Grid
            container
            alignItems={'center'}
            sx={{
                backgroundColor: 'white',
                borderRadius: 3,
            }}
        >
            <Grid
                item
                xs={6}
            >
                <Grid
                    container
                    direction={"column"}
                    spacing={2}
                >
                    <Grid
                        item
                    >
                        <ClockWidget/>
                    </Grid>
                    <Grid
                        item
                    >
                        <DateWidget/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                xs={6}
                //width={130}
            >
                <Grid
                    container
                    direction={"column"}
                    spacing={1}
                    alignItems={'flex-end'}
                    paddingRight={5}
                >
                    <Grid
                        item
                    >
                        <LogoWidget/>
                    </Grid>
                    <Grid
                        item
                    >
                        <WeatherWidget apiKey={data.apiKey} city={data.city+','+data.country} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
