import {Grid, Paper} from "@mui/material";

export const TableHeaderCustom = ({cols,cellWidths,...props}) => {
    return (
        <Grid
            container
            width={1}
            //justifyContent={'center'}
            /*sx={{
                display: 'table-row',
            }}*/
            {...props}
        >
            <Grid
                item
                xs={12}
                //justifyContent={'center'}
                sx={{
                    width: '100vh',
                }}
            >
                <Paper
                    elevation={0}
                    sx={{
                        //borderRadius: 3,
                        width: 1,
                        display: 'table',
                    }}
                >
                    <Grid
                        container
                        width={1}
                        //justifyContent={'center'}
                        sx={{
                            display: 'table-row',
                        }}
                    >
                        {
                            cols?.map((v,idx)=>{
                                return (<Grid
                                    key={idx}
                                    item
                                    sx={{
                                        padding: 2,
                                        display: 'table-cell',
                                        //width: cellWidths[idx],
                                        //border: '1px solid',
                                    }}
                                >
                                    <div style={{width: cellWidths[idx],justifyContent:'center'}}>{v}</div>
                                </Grid>);
                            })
                        }
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}
