import './App.css';
import {InnerLayout} from "./components/layout/InnerLayout";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandler from "./components/ErrorHandler";
import {ApiContextProvider} from "./hooks/UseApi";

function App() {
  return (
      <ErrorBoundary FallbackComponent={ErrorHandler}>
          <ApiContextProvider>
            <InnerLayout/>
          </ApiContextProvider>
      </ErrorBoundary>
  );
}

export default App;
