import {Grid} from "@mui/material";
import {WaitingOrders} from "./WaitingOrders";
import {ProcessedOrders} from "./ProcessedOrders";
import {ItemsStatisticsWidget} from "./ItemsStatisticsWidget";
import {useEffect, useRef, useState} from "react";
import {useApi} from "../hooks/UseApi";

export const InnerInfoPanel = () => {
    const [data,setData]=useState(null);

    const ingoLoading = useRef(false);
    const {ApiGetInfo} = useApi();

    useEffect(()=> {
        if(!ingoLoading.current && data===null) {
            ingoLoading.current=true;
            ApiGetInfo(
                (d) => {
                    if (d?.data && d?.success) {
                        console.log('InnerInfoPanel fetch0', d.data);
                        setData(d.data);
                        ingoLoading.current=false
                    } else {
                        console.error('InnerInfoPanel fetch1', d);
                        setData(null);
                        ingoLoading.current=false
                    }
                }
                , (error) => {
                    console.error('InnerInfoPanel fetch2', error);
                    setData(null);
                    ingoLoading.current=false
                });
        }
    },[ApiGetInfo,setData]);

    useEffect(()=> {
        if(data!==null) {
            console.log('InnerInfoPanel data', data)
        }
    },[data]);

    return data!==null && (
        <Grid
            container
            justifyContent={'center'}
            direction={"column"}
            spacing={1}
            xs={12}
            sx={{
                backgroundColor:'white',
                borderRadius: 3,
            }}
        >
            <Grid
                item
            >
                <WaitingOrders customer={data?.waiting?.customer} transfer={data?.waiting?.transfer}/>
            </Grid>
            <Grid
                item
            >
                <ProcessedOrders customer={data?.processed?.customer} transfer={data?.processed?.transfer}/>
            </Grid>
            <Grid
                item
            >
                <ItemsStatisticsWidget
                    grabbedItem={data?.statistics?.grabbedItem}
                    grabbedQantity={data?.statistics?.grabbedQantity}
                    customerStatisfaciton={data?.statistics?.customerStatisfaciton}
                />
            </Grid>
        </Grid>
    )
}
