import {Stack} from "@mui/material";
import {DisplayTextWithNumberWidget} from "./DisplayTextWithNumberWidget";

export const ItemsStatisticsWidget = ({grabbedItem,grabbedQantity,customerStatisfaciton,...props}) => {
    return (
        <Stack
            spacing={1}
            alignItems={'flex-end'}
            marginRight={2}
            sx={{
                textTransform: 'uppercase',
            }}
            {...props}
        >
            <DisplayTextWithNumberWidget
                key={'grabbed_item'}
                text={'kiszedett tétel'}
                number={grabbedItem}
                numberColor={'#63ba6d'}
            />
            <DisplayTextWithNumberWidget
                key={'grabbed_quantity'}
                text={'kiszedett darab'}
                number={grabbedQantity}
                numberColor={'#63ba6d'}
            />
            <DisplayTextWithNumberWidget
                key={'customer_statisfaction'}
                text={'vevői elégedettség'}
                number={customerStatisfaciton}
                numberColor={'#fd8204'}
            />
        </Stack>
    )
}
