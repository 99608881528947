import {Typography} from "@mui/material";
import {useEffect, useState} from "react";

export const ClockWidget = () => {

    const [clockState, setClockState] = useState();

    useEffect(() => {
        const t = setInterval(() => {
            const date = new Date();
            setClockState(date.toLocaleTimeString());
        }, 1000);
        return () => {
            clearInterval(t);
        }
    }, [setClockState]);

    return (
        <>
            <Typography
                sx={{
                    fontWeight: 'bold',
                    fontSize: 45,
                    textAlign: 'center'
                }}
            >
                {clockState}
            </Typography>
        </>
    )
}
