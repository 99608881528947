import {Grid, Paper} from "@mui/material";

export const TableRowCustom = ({data,colorIndex,cellWidths,...props}) => {

    /*useEffect(()=>{
        console.log('data',data);
        console.log('colorindex',data[colorIndex]);

    },[]);*/

    return data && (
        <Grid
            container
            width={1}
            justifyContent={'center'}
            /*sx={{
                display: 'table-row',
            }}*/
            {...props}
        >
            <Grid
                item
                xs={12}
                justifyContent={'center'}
                sx={{
                    width: '100vh',
                }}
            >
                <Paper
                    elevation={0}
                    sx={{
                        borderRadius: 3,
                        width: 1,
                        display: 'table',
                        backgroundColor: (colorIndex!==undefined ? data[colorIndex] : '')
                    }}
                >
                    <Grid
                        container
                        width={1}
                        justifyContent={'center'}
                        sx={{
                            display: 'table-row',
                        }}
                    >
                        {
                            data?.map((v,idx)=>{
                                if(colorIndex!==undefined && idx===colorIndex)
                                    return null;
                                return (
                                    <Grid
                                        key={idx}
                                        item
                                        sx={{
                                            display: 'table-cell',
                                            //width: cellWidths[idx],
                                            //height: 30,
                                            padding: 2,
                                            //border: '1px solid',
                                            color: 'white',
                                        }}
                                    >
                                        <div style={{width: cellWidths[idx]}}>{v}</div>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}
