import {Box, Stack, Typography} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import axios from "axios";

export const WeatherWidget = ({apiKey,city,...props}) => {
    const [wd,setWd]=useState(null);

    let loading=false;

    const getWeaterData=useCallback(()=> {
        loading=true;
        const apiUrl='https://api.openweathermap.org/data/2.5/weather?q='+city+'&appid='+apiKey+'&units=metric';
        //console.log('getWeaterData');
        //return {};
        return axios
            .get(apiUrl)
            .then((res)=>{
                //console.log('weatherdata',res);
                const main = res.data.main;
                const weather = res.data.weather[0];
                loading=false;
                return  {
                    icon: '//openweathermap.org/img/wn/'+weather.icon+'@2x.png',
                    temp: Math.ceil(main.temp),
                };
            })
            .catch((error)=>{
                console.error(error);
                loading=false;
                return null;
            })
        ;
    },[]);

    useEffect(()=>{
        if(wd===null && !loading){
            //console.log('effw');
            getWeaterData().then((d)=>{
                //console.log('d',d);
                setWd(d);
            });
        }
    },[setWd,getWeaterData]);

    //useEffect(()=>{ console.log('wd',wd)},[wd]);

    return wd!==null && (
        <Box
            component={"div"}
            sx={{
                borderRadius: 3,
                //backgroundColor: '#fd8204',
                //width: 130,
                //marginRight: 10,
                marginBottom: 2,
                //float: 'right',
                height: 80,
            }}
        >
            <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={0}
                height={1}
                width={130}
            >
                <Typography
                    sx={{
                        //color: 'white',
                        fontWeight: 'bold',
                        fontSize: 25,
                        //padding: 0,
                        //margin: 0,
                        paddingLeft: 1,
                    }}
                >
                    {wd?.temp}°C
                </Typography>
                <img
                    src={wd.icon}
                    alt={'icon'}
                />
            </Stack>
        </Box>
    );
}

