import {Stack, Typography} from "@mui/material";
import { useState} from "react";

export const DateWidget = () => {

    const [date]=useState(new Date());

    return (
        <>
            <Stack
                spacing={1}
            >
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        fontSize: 35,
                        textAlign: 'center'
                    }}
                >
                    {date.toLocaleDateString('hu-HU',{ year: 'numeric', month: '2-digit', day: '2-digit' })}
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        fontSize: 40,
                        textAlign: 'center',
                        textTransform: 'uppercase'
                    }}
                >
                    {date.toLocaleDateString('hu-HU',{ weekday: 'long' })}
                </Typography>
            </Stack>
        </>
    )
}
