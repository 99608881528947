import {Box, Grid, Typography} from "@mui/material";

export const DisplayTextWithNumberWidget = ({text,number,numberColor,numberBgColor,textColor,numberBorder,...props}) => {
    return (
        <Grid
            container
            direction={"row"}
            spacing={2}
            alignContent={"center"}
            width={'auto'}
            {...props}
        >
            <Grid
                item
                sx={{
                    marginTop: 3,
                    marginLeft: 2
                }}
            >
                <Typography
                    sx={{
                        color: textColor??'black',
                        fontWeight: 'bold',
                    }}
                >
                    {text}
                </Typography>
            </Grid>
            <Grid
                item
            >
                <Box
                    sx={{
                        padding: 2,
                        borderRadius: 2,
                        margin:0,
                        border: numberBorder??2,
                        borderColor: numberColor??'black',
                        backgroundColor:numberBgColor??''
                    }}
                >
                    <Typography
                        sx={{
                            color: numberColor??'black',
                            fontSize: 30,
                            fontWeight: 'bold',
                        }}
                    >
                        {number}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}
