/**
 * error display component for error boundary
 */
import { Card, CardActions, CardContent, CardHeader, Chip} from "@mui/material";

export default function ErrorHandler({error,resetErrorBoundary}){
    return (
        <Card>
            <CardHeader
                title={"Whooops something went wrong"}
            />
            <CardContent>
                <pre>
                    {error.message}
                </pre>
            </CardContent>
            <CardActions>
                <Chip
                    label={"Try again"}
                    component={"a"}
                    href={resetErrorBoundary}
                    clickable
                />
            </CardActions>
        </Card>
    );
}
