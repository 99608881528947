import { Box} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import {useApi} from "../hooks/UseApi";

export const LogoWidget = () => {
    const [data,setData]=useState(null);

    const {ApiGetLogo} = useApi();

    /**
     * strict mode miatt trükk: https://github.com/facebook/react/issues/24502
     * index.js -ben a  <React.StrictMode> miatt 2x hívódhatnak meg a useEffectek..
     */
    const logoLoading = useRef(false);

    useEffect(()=> {
        if(!logoLoading.current && data===null) {
            logoLoading.current=true;
            ApiGetLogo(
                (d) => {
                    if (d?.data && d?.success) {
                        console.log('LogoWidget fetch0', d.data);
                        setData(d.data);
                        logoLoading.current=false
                    } else {
                        console.error('LogoWidget fetch1', d);
                        setData(null);
                        logoLoading.current=false
                    }
                }
                , (error) => {
                    console.error('LogoWidget fetch2', error);
                    setData(null);
                    logoLoading.current=false
                });
        }
    },[ApiGetLogo,setData]);

    /*
    let loading=false;
    const getData=useCallback(()=> {
        loading=true;
        const apiUrl='http://localhost/api/logo';

        return axios
            .get(apiUrl)
            .then((res)=>{
                loading=false;
                return res.data;
            })
            .catch((error)=>{
                console.error(error);
                loading=false;
                return null;
            })
            ;
    },[]);

    useEffect(()=>{
        if(data===null && !loading){
            console.log('logo_hook');
            getData().then((d)=>{
                if(d?.data && d?.success) {
                    console.log('logo_hook fetch', d.data);
                    setData(d.data);
                } else {
                    console.log('logo_hook fetch', d);
                }
            });
        }
    },[setData,getData]);
    */

    return (data!==null) && (
        <Box
            component={"img"}
            src={data?.dataURI}
        />
    )
}
