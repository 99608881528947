import {InnerJobListPanel} from "../InnerJobListPanel";
import {InnerInfoPanel} from "../InnerInfoPanel";
import {InnerLogoPanel} from "../InnerLogoPanel";
import {Paper, Stack} from "@mui/material";

export const InnerLayout = () => {

    return (
        <Paper
            sx={{
                backgroundColor:'#011689',
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Stack
                spacing={2}
                direction={"row"}
                sx={{
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <InnerJobListPanel />
                <Stack
                    spacing={2}
                    width={1}
                >
                    <InnerLogoPanel/>
                    <InnerInfoPanel/>
                </Stack>
            </Stack>
        </Paper>
    )
}
