import {
    Card,
    CardContent,
    Stack,
} from "@mui/material";
import {useEffect, useCallback, useState, useRef} from "react";
import {TableHeaderCustom} from "./TableHeaderCustom";
import {TableRowCustom} from "./TableRowCustom";
import {useApi} from "../hooks/UseApi";

export const InnerJobListPanel = () => {
    ///TODO: adatszerkezetet átrakni tömbről objektumra, nem kell, hogy univerzális legyen, inkább nevesített adattagok legyenek

    ///TODO: nem megy a felx miatt rendesen, majd másik komponens kell valószínű a táblázatszerű megjelenítésre
    const headerWidths=[
        10     //kapu'
        ,10    //'azonosito'
        ,10     //'állapot'
        ,20     //'ügyfél'
        ,20     //'bizonylat'
        ,20     //'kezdés'
        ,20     //'tétel/db'
        ,20     //'felelős'
        ,20     //'prioritás'
    ];

    const cellWidths=[0,...headerWidths];

    const [data,setData]=useState(null);

    const jobsLoading = useRef(false);

    const {ApiGetJobs} = useApi();

    useEffect(()=> {
        if(!jobsLoading.current && data===null) {
            jobsLoading.current=true;
            ApiGetJobs(
                (d) => {
                    if (d?.data && d?.success) {
                        console.log('InnerJobListPanel fetch0', d.data);
                        setData(d.data);
                        jobsLoading.current=false
                    } else {
                        console.error('InnerJobListPanel fetch1', d);
                        setData(null);
                        jobsLoading.current=false
                    }
                }
                , (error) => {
                    console.error('InnerJobListPanel fetch2', error);
                    setData(null);
                    jobsLoading.current=false
                });
        }
    },[ApiGetJobs,setData]);

    useEffect(()=> {
        if(data!==null) {
            console.log('InnerJobListPanel data', data)
        }
    },[data]);

    return data!==null && (
        <Card sx={{
            minWidth: 4/5,
            borderRadius: 3,
            height: '100vh',
            overflow: 'auto',
            //backgroundColor: 'red',
            textTransform: 'uppercase',
        }}>
            <CardContent>
                <Stack
                    width={1}
                    spacing={2}
                    sx={{
                        display: 'table',
                    }}
                >
                    <TableHeaderCustom
                        cols={[
                            'kapu'
                            ,'azonosito'
                            ,'állapot'
                            ,'ügyfél'
                            ,'bizonylat'
                            ,'kezdés'
                            ,'tétel/db'
                            ,'felelős'
                            ,'prioritás'
                        ]}
                        cellWidths={headerWidths}
                        sx={{
                            fontSize: 25,
                            fontWeight: 'bold',
                        }}
                    />
                    {
                        data.map((d,idx)=>{
                            return (<TableRowCustom
                                key={idx}
                                data={d}
                                colorIndex={0}
                                cellWidths={cellWidths} //a színnek is kell adni a indexálás helyesség miatt!
                                sx={{
                                    fontSize: 25,
                                }}
                            />)
                        })
                    }

                </Stack>
            </CardContent>
        </Card>
    );
}
