import {Grid, Stack, Typography} from "@mui/material";
import {DisplayTextWithNumberWidget} from "./DisplayTextWithNumberWidget";

export const ProcessedOrders = ({customer,transfer,...props}) => {
    return (
        <Stack
            sx={{
                width: 1,
                textTransform: 'uppercase',
            }}
            spacing={2}
            {...props}
        >
            <Typography
                component={"center"}
                sx={{

                    fontWeight: 'bold',
                    fontSize: 20,
                }}
            >
                teljesített rendelések
            </Typography>
            <Grid
                container
                justifyContent={"left"}
            >
                <Grid
                    item
                    xs={6}
                >
                    <DisplayTextWithNumberWidget text={"vevő"} number={customer} numberColor={'white'} numberBgColor={"#63ba6d"}/>
                </Grid>
                <Grid
                    item
                    xs={6}
                >
                    <DisplayTextWithNumberWidget text={"kiszállítás"} number={transfer} numberColor={'white'} numberBgColor={"#8baf8b"} />
                </Grid>
            </Grid>
        </Stack>
    )
}
